import { AccountId, EVALUATION_ITEM_TYPES, EmailRecipientGroup, EvaluationCoach, EvaluationId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { isOrgAdmin } from "../../compute";
import _ from "lodash";
import { common__hashObject } from "../common.api";
import { ObjectKeys, ObjectValues, shouldSendEmail } from "../../utils";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import { send } from "process";
import moment from "moment";
import Papa from "papaparse";

export async function evaluation__server__exportCoachEvaluationsForOrg(p: {
  selfAccountId: string;
  evaluationIds: EvaluationId[];
  orgId: string;
  locale: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const {
    injectedServerLibraries: { sendGrid }
  } = getServerHelpers();
  const [senderAccount, org, evaluationsData] = await Promise.all([
    h.Account.getDoc(p.selfAccountId),
    h.Org.getDoc(p.orgId),
    h.Evaluation.getDocs(p.evaluationIds)
  ]);

  if (!senderAccount || !org || !org.accounts[p.selfAccountId]?.exists) {
    throw new Error("This Account cannot export for that org: " + p.selfAccountId + ": " + p.orgId);
  }

  const evaluations = _.compact(evaluationsData).filter(evaluation => evaluation.type === "coach") as EvaluationCoach[];
  const evaluationsWithHashOfItems = evaluations.map(evaluation => {
    const itemsString = Object.values(evaluation.items)
      .map(item => item.title + "-" + item.type)
      .sort()
      .join(",");
    return {
      evaluation,
      hashOfItems: common__hashObject({
        obj: itemsString
      })
    };
  });

  const groups = _.groupBy(evaluationsWithHashOfItems, a => a.hashOfItems);
  const allAccountIds = ObjectKeys(
    evaluations.reduce((acc, val) => {
      acc[val.evaluatorId] = true;
      acc[val.coachAccountId] = true;
      return acc;
    }, {} as Record<AccountId, true>)
  );
  const allAccounts = _.compact(await h.Account.getDocs(allAccountIds));

  let csvString = "";

  Object.values(groups).forEach(group => {
    if (group.length) {
      const items = Object.values(group[0].evaluation.items);
      let fields: string[] = [
        translate.common(p.locale).Name,
        translate({ defaultMessage: "Evaluator", serverLocale: p.locale }),
        translate.common(p.locale).Date,
        translate.common(p.locale).Title,
        translate({ defaultMessage: "Rating Scale", serverLocale: p.locale })
      ];
      const data: any[] = [];
      group.forEach((evaluationData, index) => {
        let rowData: Record<string, string> = {};
        const coachAccount = allAccounts.find(acc => acc.id === evaluationData.evaluation.coachAccountId);
        const evaluatorAccount = allAccounts.find(acc => acc.id === evaluationData.evaluation.evaluatorId);
        const imageUris = Object.values(evaluationData.evaluation.imageUris ?? {});
        rowData[translate.common(p.locale).Name] = coachAccount ? `${coachAccount.firstName} ${coachAccount.lastName}` : "";
        rowData[translate({ defaultMessage: "Evaluator", serverLocale: p.locale })] = evaluatorAccount
          ? `${evaluatorAccount.firstName} ${evaluatorAccount.lastName}`
          : "";
        rowData[translate.common(p.locale).Date] = evaluationData.evaluation.sentAtMS
          ? dateFormatters.mm_dd_yyyy(moment(evaluationData.evaluation.sentAtMS).toDate(), p.locale)
          : "";
        rowData[translate.common(p.locale).Title] = evaluationData.evaluation.title;
        rowData[
          translate({ defaultMessage: "Rating Scale", serverLocale: p.locale })
        ] = `${evaluationData.evaluation.ratingScale}`;
        items.forEach(item => {
          const matchingItemInEval = Object.values(evaluationData.evaluation.items).find(
            i => i.title === item.title && i.type == item.type
          );

          let value = "";
          if (matchingItemInEval) {
            let returnString = "";
            if ("rating" in matchingItemInEval) {
              value += matchingItemInEval.rating;
            }
            if ("note" in matchingItemInEval) {
              value += ` (${matchingItemInEval.note})`;
            }
            if ("text" in matchingItemInEval) {
              value += matchingItemInEval.text;
            }
          }
          rowData[item.title] = value;
          fields.push(item.title);
        });
        rowData[translate({ defaultMessage: "Image 1", serverLocale: p.locale })] = imageUris.length > 0 ? imageUris[0] : "";
        rowData[translate({ defaultMessage: "Image 2", serverLocale: p.locale })] = imageUris.length > 1 ? imageUris[1] : "";
        rowData[translate({ defaultMessage: "Image 3", serverLocale: p.locale })] = imageUris.length > 2 ? imageUris[2] : "";
        rowData[translate({ defaultMessage: "Image 4", serverLocale: p.locale })] = imageUris.length > 3 ? imageUris[3] : "";
        rowData[translate({ defaultMessage: "Image 5", serverLocale: p.locale })] = imageUris.length > 4 ? imageUris[4] : "";
        fields.push(translate({ defaultMessage: "Image 1", serverLocale: p.locale }));
        fields.push(translate({ defaultMessage: "Image 2", serverLocale: p.locale }));
        fields.push(translate({ defaultMessage: "Image 3", serverLocale: p.locale }));
        fields.push(translate({ defaultMessage: "Image 4", serverLocale: p.locale }));
        fields.push(translate({ defaultMessage: "Image 5", serverLocale: p.locale }));
        data.push(rowData);
      });
      csvString += Papa.unparse(data, { columns: _.uniq(fields) });
      csvString += "\n\n";
    }
  });

  if (shouldSendEmail(senderAccount.email)) {
    const subject = translate({ defaultMessage: "Evaluations Export", serverLocale: p.locale });
    await sendGrid.send({
      from: "Ollie Sports <noreply@olliesports>",
      to: senderAccount.email,
      subject,
      html: `
    <!DOCTYPE html>
    <html>
    <head>
    <meta charset="utf-8">
    <title>${translate({ defaultMessage: "CSV Export", serverLocale: p.locale })}</title>
    <meta name="description" content="${subject}">
    <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    </head>
    <body>
    <p>${translate({
      defaultMessage: "Attached to this email you will find your exported evaluations",
      serverLocale: p.locale
    })}.</p>
    </body>
    </html>
    `,
      attachments: [
        {
          content: Buffer.from(csvString).toString("base64"),
          filename: `${translate.common(p.locale).Evaluations}.csv`,
          type: "application/csv",
          disposition: "attachment",
          contentId: "roster"
        }
      ]
    });
  } else {
    console.log(
      `Not sending email because ${senderAccount.email} is either not whitelisted or this is prod and the email is an ollie-testing email.`
    );
  }

  // SERVER_ONLY_TOGGLE
}

evaluation__server__exportCoachEvaluationsForOrg.auth = (req: any) => {
  return validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
